.invaders-wave-1 {
    width: 100%;
    max-width: 300px;
    border-radius: 15px;
}

.invaders-wave-1-group {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

.invaders-wave-1-group .invaders-wave-1 {
    margin: 5px;
}