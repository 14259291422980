.scared-earthling-img {
    width: 100%;
    max-width: 300px;
    border-radius: 15px;
}

.scared-earthling-img-group {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

.scared-earthling-img-group .scared-earthling-img {
    margin: 5px;
}