.navbar {
  overflow: hidden;
  position: fixed; 
  top: 10px; 
  left: 20px;
  right: 20px;
  padding: 5px 10px;
  z-index: 2000;

  display: flex;
  flex-direction: row-reverse;
  align-items: unset;
  justify-content: unset;
}

.navbar .button {
  width: 60px;
  height: 60px;
}

.navbar .button+.button {
  margin-right: 10px;
}

.navbar .button img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: brightness(10);
}

#btn-join-the-resistance {
  width: 210px;
  font-size: larger;
}

@media screen and (max-width: 800px) {
  .navbar .button {
    height: 40px;
    width: 40px;
  }

  #btn-join-the-resistance {
    width: 150px;
    font-size: small;
  }
}