.support-levels {
  text-align: center;
  margin-top: 30px;
  font-size: large;
}

.support-levels .row {
  margin-bottom: 15px;
}

.support-levels .row > .empty-col {
  background-color: transparent;
  padding: 10px;
}

.support-levels .row > div {
  position: relative;
  --margin: 10px;
  padding: 10px;
  padding-bottom: 100px;
  background-color: #814ae470;
}

.support-levels .footer {
  position: absolute;
  bottom: 20px;
  margin: auto;
  width: 100%;
}

.support-levels h2 {
  font-weight: bold;
}

.support-levels .row > div img {
  width: inherit;
  max-width: 250px;
  width: 100%;
  border-radius: 20px;
}

.support-levels ul {
  text-align: left;
}

.support-levels a {
  text-align: center;
  margin: auto;
}

@media screen and (max-width: 800px) {
  .support-levels .row {
    width: 100%;
  }
}