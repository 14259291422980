.link {
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  padding: 4px;
  font-weight: bolder;
}

.link:hover {
  color:#fff;
  background-color: #512d90;
  border-radius: 4px;
}

.button {
  cursor: pointer;
  background-color: #8247e5;
  color: #fff;

  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 200px;
  height: 60px;
  text-decoration: none;
}

.button.button-reverse {
  background-color: #fff;
  color: #8247e5;
}

.button:hover {
  background-color: #512d90;
  color: #fff;
}

.button.button.button-reverse:hover {
  background-color: #512d90;
  color: #fff;
}

.button img {
  width: 80%;
  height: 80%;
  object-fit: contain;
}

.button-home {
  position: fixed;
  top: 0px;
  left: 5px;
  z-index: 2000;
  width: 100px;
}

.button-home img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.button-main-link {
  width: unset;
  padding: 15px;
  margin-top: 5px;
}