.section-collection {
  text-align: center;
  padding: 20px;
  font-size: large;
}

.section-collection h1 {
  margin-bottom: 20px;
  font-weight: bold;
}

.section-collection p {
  font-size: larger;
}

.section-collection .nft-collection {
  background-color: #fff;
  color: #8247e5;

  border-radius: 15px;
  padding: 10px;
  max-width: 500px;
  width: 100%;
}

.section-collection .nft-collection-col {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
}

.section-collection .nft-collection-col .row + .row {
  border-top: 1px solid #8247e5;
}

.section-collection .nft-collection .header {
  background-color: #8247e5;
  color: #fff;

  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 5px 10px;
  margin-bottom: 5px;
}

.section-collection .nft-collection .nft-q {
  font-weight: bold;
}

.section-collection .button.opensea {
  margin-top: 5px;
  width: 100%;
  font-size: x-large;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.section-collection-qa .question, .section-collection-qa .answer {
  text-align: left;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #fff;
  color: #8247e5;
  width: fit-content;
  font-weight: bold;
  margin-top: 15px;
}

.section-collection-qa .answer {
  margin-left: auto;
  color: #000;
  max-width: 80%;
}