.roadmap-entry {
  text-align: center;
  padding: 15px;
  margin-top: 30px;
  font-size: larger;
  background-color: #814ae470;
  width: 80%;
}

.roadmap-entry h2 {
  font-weight: bold;
}