.parallax {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  perspective: 2px;
  
  background: rgba(75,122,171,1);
  background-image: url("../css/images/banner/banner-clouds.png"), linear-gradient(90deg, rgba(75,122,171,1) 0%, rgba(75,122,171,1) 0%, rgba(128,175,223,1) 100%);
  background-repeat: repeat-y;
  background-position: top left;
  background-size: cover;
}

.section {
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: center;
  color: white;
  min-height: 100vh;
  width: 100%;
  background-color: transparent;
}

.section-parallax {
  transform: translateZ(-1px) scale(1.5);  
  z-index: -1;

  background-position: center right;
  background-size: cover;

  align-items: center;
  justify-content: center;
}

.section.main {
  background-image: url("../css/images/banner/banner-spaceship.png"), url("../css/images/banner/banner-title.png");
}

.section-small {
  min-height: 200px;
}

.section.invaders {
  background-image: url("../css/images/alien.spaceships.png");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 200px;
  min-height: 200px;
  width: 200px;
  animation: shift 8s linear infinite;
  -webkit-animation: shift 8s linear infinite;
}

@keyframes shift {
    0% {
      left: -20vw;
      transform: scale(2) scaleX(1) translateZ(-1px) translateY(-150px);
      -webkit-transform: scale(2) scaleX(1) translateZ(-1px) translateY(-150px);
      z-index: 20;
    }
    49% {
      transform: scale(2) scaleX(1) translateZ(-1px) translateY(50px);
      -webkit-transform: scale(2) scaleX(1) translateZ(-1px) translateY(50px);
      z-index: 20;
    }
    50% {
      left: 100vw;
      transform: scaleX(-1) translateZ(-1px);
      -webkit-transform: scaleX(-1) translateZ(-1px);
      z-index: -1;
    }  
    100% {
      left: -20vw;
      transform: scaleX(-1) translateZ(-1px) translateY(-150px);
      -webkit-transform: scaleX(-1) translateZ(-1px) translateY(-150px);
      z-index: -1;
    }
}

.section.resistance {
  background-color: #ffffff66;
  background-image: url("../css/images/resistance-bunch.png");
  background-repeat: no-repeat;
  background-position: top center;
  min-height: 1000px;
}

.section.rebels {
  background-color: #ffffff66;
  background-image: url("../css/images/rebel-banner.png");
  background-repeat: no-repeat;
  background-position: top center;
  min-height: 400px;
}

.section-static {
  min-height: unset;
  width: 70vw;
  margin: auto;
  padding: 0px 10px;  

  background-color: #8247e5F5;
  color: #fff;
}

.section-transparent {
  background-color: transparent;
}

.section-static-qa {
  width: 70vw;
}

.section-roadmap-entries, .section-giveaway-entries {
  flex-direction: column;
}

.section-footer {
  height: 150px;
  min-height: 150px;
  background-color: #fff;
  color: #000;
  display: flex;
  align-items: center;
  padding: 20px;
}

.section h1 {
  font-size: xxx-large;
  padding-bottom: 5px;
  background-color: #ffffff55;
}

.section #arrow-down {
  font-size: 3rem;
  text-align: center;
  position: absolute;
  right: 10px;
  left: 10px;
  color: #fff;
  animation: move 2s 3s forwards;
  --animation-iteration-count: 2;
  opacity: 0;
}

.section #arrow-down::after {
  content: '↓';
  display: block;
  font-size: 2em;
  font-weight: 100;
}

.section #interactive-collection {
  font-size: 2.2em;
  font-weight: bold;
  position: absolute;
  bottom: 30px;
  right: 30px;
  left: 30px;
  text-align: center;
  animation: appear 2s 1s forwards;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #522f8d55;
  border-radius: 10px;
  padding: 10px 0px;
}

.section #interactive-collection small {
  font-size: 0.7em;
}

@keyframes appear{
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes move{
  0% { bottom: 40%; opacity: 1; }
  100% { bottom: 20px; opacity: 1; }
}


@media screen and (max-width: 800px) {
  .section {
    font-size: small;
  }

  .section-static {
    width: 100vw;
  }
}